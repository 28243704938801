<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
			
					<a-form-item class="ui-form__item" label="所属影院" name="cinemaIds">
						<a-select placeholder="请选择" v-model:value="formState.cinemaIds" style="min-width: 180px;max-width: 500px;" mode="multiple" @change="onCinemaChange">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id" > {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				
				<a-row v-show="showAll">
					<a-form-item class="ui-form__item" label="商品名称" name="goodsName">
						<a-input v-model:value="formState.goodsName" placeholder="请输入商品名称"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="订单编号" name="orderNo">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单编号"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="订单来源" name="source">
						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">微信小程序</a-select-option>
							<a-select-option :value="2">抖音小程序</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="核销码" name="verificationCode">
						<a-input v-model:value="formState.verificationCode" placeholder="请输入核销码"></a-input>
					</a-form-item>
					
					<a-form-item label="发货状态" name="orderStatus" class="ui-form__item">
						<a-select v-model:value="formState.orderStatus" placeholder="请选择发货状态" style="width: 190px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">未发货</a-select-option>
							 <a-select-option :value="2">已发货</a-select-option>
<!--							<a-select-option :value="3">已收货</a-select-option>-->
							<a-select-option :value="5">未核销</a-select-option>
							<a-select-option :value="6">已核销</a-select-option>
							<a-select-option :value="7">已完成</a-select-option>
							<a-select-option :value="4">已退款</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="会员手机" name="userPhone">
						<a-input v-model:value="formState.userPhone" placeholder="请输入会员手机号"></a-input>
					</a-form-item>
					 
					<a-form-item class="ui-form__item" label="销售时间段">
						<a-range-picker v-model:value="time" :disabledDate="disabledDate"/>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
			            <span v-permission="['report_revenue_goodsSale_import']">
							<ExportReport type="storeSalesStatistical" :searchData="searchData"></ExportReport>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			
			<a-table style="margin-top: 10px;" rowKey="id" :columns="columns" :dataSource="list" :pagination="pagination" :scroll="{ x: 3200 }">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'date'">
						{{ transDateTime(record.createTime , 1) }}
					</template>
					<template v-if="column.key === 'week'">
						星期{{ ['日', '一', '二', '三', '四', '五', '六'][moment(record.createTime *1000).day()] }}
					</template>
					<template v-if="column.key === 'createTime'">
						{{ transDateTime(record.createTime)}}
					</template>
					<template v-if="column.key === 'goodsInfo'">
						<!-- <a-image style="width: 50px;" src=""></a-image> -->
						{{ record.goodsName }}
					</template>
					<template v-if="column.key === 'timeCard'">
						{{ record.numberCardName || '--' }}
					</template>
					<template v-if="column.key === 'yearCard'">
						{{ record.yearCardName || '--' }}
					</template>
					<template v-if="column.key === 'storedCard'">
						<div v-if="record.goodsInfoVO.isRechargeCard">
							<div>卡等级：</div>
							<div>初始金额: {{ record.goodsInfoVO.initAmount }}元</div>
						</div>
						<div v-else>--</div>
					</template>
					<template v-else-if="column.key === 'coupon'">
						<div v-if="record.goodsInfoVO.isCoupon">
							<div style="margin-bottom: 5px;" v-if="record.goodsInfoVO.ticketBatchNum">
								<div>兑换券批次号：<span style="color: rgb(102, 102, 102);">{{record.goodsInfoVO.ticketCouponList.length ? record.goodsInfoVO.ticketCouponList[0].batchNo : '' }}</span></div>
								<div>兑换券发放数量：<span style="color: rgb(102, 102, 102);">{{record.goodsInfoVO.ticketBatchNum}}张</span></div>
								<div>
									兑换券号：
									<a-tag v-for="(item, i) in record.goodsInfoVO.ticketCouponList" :key="i">
										{{ item.barcode }}
									</a-tag>
								</div>
							</div>
							<div v-if="record.goodsInfoVO.snackBatchNum">
								<a-divider style="margin: 6px 0;" v-if="record.goodsInfoVO.ticketBatchNum"/>
								<div>卖品券批次号：<span style="color: rgb(102, 102, 102);">{{record.goodsInfoVO.snackCouponList.length ? record.goodsInfoVO.snackCouponList[0].batchNo : '' }}</span></div>
								<div>卖品券发放数量：<span style="color: rgb(102, 102, 102);">{{record.goodsInfoVO.snackBatchNum}}张</span></div>
								<div>
									卖品券号：
									<a-tag v-for="(item, i) in record.goodsInfoVO.snackCouponList" :key="i">
										{{ item.barcode }}
									</a-tag>
								</div>
							</div>
						</div>
						<span v-else>--</span>
					</template>
					<template v-if="column.key === 'refund'">
						{{ record.refund || '--' }}
					</template>
					<template v-if="column.key === 'deliveryType'">
						<div><a-tag color="cyan">{{ ['无', '自提', '快递'][record.deliveryType - 1] }}</a-tag></div>
					</template>
					<template v-if="column.key === 'source'">
						<div>
							<a-tag color="green" v-if="record.source === 1">
								微信小程序
							</a-tag>
							<a-tag color="blue" v-else> 抖音小程序 </a-tag>
						</div>
					</template>
					<template v-if="column.key === 'status'">
						<div>
							<span style="color: #fd6846;">{{ transStatus(record.status, record.deliveryType) }}</span>
							<div v-if="record.status !== 'AFTERSALE_FINISH'">
								<div v-if="record.deliveryType === 2">核销码：{{ record.verificationCode }}</div>
								<div v-if="record.deliveryType !== 3">
									<div v-if="record.verificationUserName">
										<div>核销人员：{{ record.verificationUserName }}</div>
										<div>{{ transDateTime(record.finishTime) }}</div>
									</div>
								</div>
								<div v-else>
									<div v-if="record.deliveryFee">运费：{{ record.deliveryFee }}</div>
									<div v-if="record.sendGoodsUserName">
										<div>发货人员：{{ record.sendGoodsUserName }}</div>
										<div>{{ transDateTime(record.sendGoodsTime) }}</div>
									</div>
								</div>
							</div>
							<div v-else>
								退款备注：{{ record.refundRemark || '--' }}
							</div>
						</div>
					</template>
					<template v-if="column.key === 'info'">
						<div>用户名称：{{ record.userName || '--' }}</div>
						<div>手机号码：{{ record.userPhone }}</div>
					</template>
				</template>
				<template #summary v-if="list && list.length">
					<a-table-summary-row>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.num || '' }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：￥{{ total.payment || 0 }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：￥{{ total.distributionPrice || 0 }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：￥{{ total.refund || 0 }}
						</a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
					</a-table-summary-row>
				</template>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import ExportReport from '@/components/exportReport/exportReport.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { storeSalesStatistical } from '@/service/modules/report.js';
	export default {
		components: { Icon, ExportReport },
		data(){
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				time: [],
				formState: {
					// organizationId: 0,
					cinemaIds: [0],
					source: 0,
				},
				searchData: {},
				list:[],
				columns: [{
					title: '日期',
					key: 'date',
					width: 130
				}, {
					title: '星期',
					key: 'week',
					width: 100
				}, {
					title: '影院名称',
					dataIndex: 'cinemaName',
					width: 250
				}, {
					title: '订单号',
					dataIndex: 'orderNo',
					width: 180
				}, {
					title: '商品信息',
					key: 'goodsInfo',
				}, {
					title: '销售时间',
					key: 'createTime',
					width: 200
				}, {
					title: '次卡等级',
					key: 'timeCard',
					width: 200
				}, {
					title: '年卡等级',
					key: 'yearCard',
					width: 200
				}, {
					title: '线上会员卡等级',
					key: 'storedCard',
					width: 230
				}, {
					title: '券信息',
					key: 'coupon',
					width: 250
				}, {
					title: '销售数量',
					dataIndex: 'num',
					width: 150
				}, {
					title: '销售金额(元)',
					dataIndex: 'payment',
					width: 180
				}, {
					title: '分销金额(元)',
					dataIndex: 'distributionPrice',
					width: 180
				}, {
					title: '退款金额',
					width: 180,
					key: 'refund'
				}, {
					title: '配送方式',
					key: 'deliveryType',
					width: 100,
					align: 'center'
				}, {
					title: '订单来源',
					key: 'source',
					width: 120,
					align: 'center'
				}, {
					title: '订单状态',
					key: 'status',
					width: 200
				}, {
					title: '会员信息',
					key: 'info',
					width: 200
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				total: {}
			}
		},
		created(){
			// this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				if(this.searchData.cinemaIds && this.searchData.cinemaIds.length && this.searchData.cinemaIds.indexOf(0) === -1) {
					this.searchData.cinemaIds = this.searchData.cinemaIds.join(',');
				} else {
					this.searchData.cinemaIds = undefined;
				}
				if (this.time && this.time.length) {
					this.searchData.startTime = this.moment(this.time[0].startOf('day')).unix();
					this.searchData.endTime = this.moment(this.time[1].endOf('day')).unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.formState.cinemaIds = [0];
				this.time = [];
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.list = [];
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await storeSalesStatistical({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.total = ret.data.list[0];
						this.list = ret.data.list.splice(1, this.pagination.pageSize);
					}
				} catch (error) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onCinemaChange(keys) {
				if (keys.indexOf(0) !== -1) {
					keys.splice(keys.indexOf(0), 1);
				}
				if (keys.length === 0) {
					keys = [0];
				}
				this.formState.cinemaIds = keys;
			},
			disabledDate(current) {
			    return current && current > this.moment().endOf('day');
			},
			transStatus(val, type) {
				if (val === 'WAIT_BUYER_PAY') {
					return '待支付';
				}
				if (val === 'SYSTEM_CANCEL') {
					return '系统超时取消';
				}
				if (val === 'CUSTOMER_CANCEL') {
					return '用户自行取消';
				}
				if (val === 'WAIT_SELLER_SEND_GOODS') {
					return '待发货'
				}
				if (val === 'WAIT_CHECK') {
					return '待核销'
				}
				if (val === 'TRADE_FINISH') {
					if (type === 2) {
						return '已核销'
					} else if (type == 3) {
						return '已收货';
					} else {
						return '订单完成'
					}
				}
				if (val === 'WAIT_BUYER_CONFIRM_GOODS') {
					return '待收货'
				}
				if (val === 'AFTERSALE_FINISH') {
					return '已退款'
				}
				if (val === 'WAIT_COMMENT') {
					return '已收货'
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
